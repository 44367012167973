<template>
  <div class="rank">
    <div class="top-wrapper">
      <img class="banner" src="@/assets/image/rank-img/banner.png" />
      <RankTop :topRankList="topRankList" />
    </div>

    <div class="card-wrapper" v-if="showCard">
      <RankCard :userInfo="userInfo" />
    </div>

    <el-tabs v-model="activeTab" stretch class="rank-tabs">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.tabName"
        :label="item.tabTitle"
        :name="item.tabName"
      >
      </el-tab-pane>
    </el-tabs>

    <div class="common-wrapper">
      <RankCommon :commonRankList="commonRankList" />
    </div>
    <el-tooltip class="backToCommunity" content="返回社区" placement="left">
      <el-button
        circle
        icon="el-icon-back"
        @click="
          () => {
            this.$router.back();
          }
        "
      ></el-button>
    </el-tooltip>
  </div>
</template>
<script>
import { getRankList } from '@/api/rank';
import { getUserInfo } from '@/api/user';
import Vue from 'vue';

export default {
  components: {
    RankTop: () => import('./RankTop.vue'),
    RankCard: () => import('./RankCard.vue'),
    RankCommon: () => import('./RankCommon.vue'),
  },
  data() {
    return {
      rankList: [],
      userInfo: {},
      activeTab: '',
      tabList: [
        { tabName: 'hotNum', tabTitle: '综合排行榜' },
        { tabName: 'collectNum', tabTitle: '被收藏排行榜' },
        { tabName: 'starNum', tabTitle: '被点赞排行榜' },
        { tabName: 'viewNum', tabTitle: '被阅览排行榜' },
        { tabName: 'workNum', tabTitle: '作品数量排行榜' },
      ],
      showCard: false,
    };
  },
  computed: {
    topRankList() {
      return this.rankList.slice(0, 3);
    },
    commonRankList() {
      return this.rankList.slice(3);
    },
  },
  watch: {
    async activeTab() {
      try {
        const rankList = await getRankList({
          topType: this.activeTab,
          userId: Vue.ls.get('Login_Userid')||'',
        });
        if (rankList.success) {
          this.rankList = rankList.result.rankingList;
          if (rankList.result.currentUserInfo) {
            this.userInfo = rankList.result.currentUserInfo;
            this.showCard = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.activeTab = 'hotNum';

    // (async () => {
    //   try {
    //     const userInfo = await getUserInfo({ uid: Vue.ls.get('Login_Userid') });
    //     if (userInfo.success) {
    //       this.userInfo = userInfo.result;
    //       this.showCard = true;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     this.showCard = false;
    //   }
    // })();
  },
};
</script>
<style lang="scss" scoped>
.rank {
  width: 1000px;
  margin: 0 auto;
  .top-wrapper {
    width: 100%;
    height: 300px;
    margin: 40px 0 20px;
    position: relative;
    .banner {
      width: 100%;
      height: 100%;
    }
  }
  .card-wrapper {
    margin-bottom: 30px;
  }
  .common-wrapper {
    margin-top: 20px;
  }
  .backToCommunity {
    position: fixed;
    right: 36px;
    bottom: 100px;
    z-index: 99999;
  }
}
</style>
<style lang="scss">
.rank-tabs {
  .el-tabs__nav-wrap {
    overflow: visible;
    &::after {
      bottom: -10px;
    }
  }
  .el-tabs__active-bar {
    background-color: #5f80ff;
    width: 68px !important;
    margin-left: 50px;
  }
  .el-tabs__item {
    color: #6a6a6a;
    font-size: 20px;
  }
  .el-tabs__item:hover {
    color: #5f80ff;
  }
  .el-tabs__item.is-active {
    color: #5f80ff;
    font-weight: bold;
  }
}
</style>