import {
  axios
} from '@/utils/request'

export function getRankList(parameter) {
  return axios({
    url: '/teaching/community/coder/top',
    method: 'get',
    params: parameter
  })
}

export function getAddressList(parameter) {
  return axios({
    url: '/mall/AddressManagement/getAddress',
    method: 'get',
    params: parameter
  })
}

export function addAddressInfo(parameter) {
  return axios({
    url: '/mall/AddressManagement/add',
    method: 'post',
    data: parameter
  })
}

export function getGiftList(parameter) {
  return axios({
    url: '/mall/OrderManagement/page',
    method: 'get',
    params: parameter
  })
}

export function updateGiftInfo(parameter) {
  return axios({
    url: '/mall/OrderManagement/edit',
    method: 'put',
    data: parameter
  })
}
